import React, { useEffect, useState } from 'react';
import { Button, Nav } from 'react-bootstrap';
import './css/Main.css'
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';

const Footer = ({page, BG_color }) => {

    const location = useLocation()
    const [activepage, setactivepage] = useState('/')

    const pathname = location.pathname
    console.log(pathname)

    useEffect(() => {
        setactivepage(pathname)
    }, [])


    return (
        <>
            <div
             className={`Footer-Container ${BG_color && page ==='cafe'?'cafe':page == 'gallery' ? 'GalleryBackground' : 'defaultPseudo'}`}
             id="Zenko-Footer"
                style={
                    BG_color !== undefined
                        ? { background: `${BG_color}` }
                        : { background: `transparent linear-gradient(0deg, #EEFCFF00 0%, #FAF0F3 100%) 0% 0% no-repeat padding-box` }
                }
            >
                <div className='Footer d-flex row'>
                    <div className='col-5 address'>
                        <div className='w-50'>
                            <img src={require('../assets/logo.png')} alt="Logo" width="50" height="50" />
                        </div>
                        <div className='d-flex Footer-Item'>
                            <img src={require("../assets/icon/location.png")} />
                            <span style={{ marginTop: '0px' }}>Zenko Play Cafe, Near. Dilliwala Dairy Al-Amin Society, Opp Tender Buds, Burhanj Baug-B Colony, Salisbury Park, Bibwewadi, Pune, Maharashtra 411037</span>
                        </div>
                        <div className='d-flex Footer-Item'>
                            <img src={require("../assets/icon/call.png")} />
                            <span>+91 8282818135</span>
                        </div>
                        <div className='d-flex Footer-Item'>
                            <img src={require("../assets/icon/email.png")} />
                            <span>Zenkoplaycafe@gmail.com</span>
                        </div>
                    </div>

                    <div className='col-3 pt-4 text-center follow-us'>
                        <h5 className='pb-2'>Follow Us</h5>
                        <div className='d-flex Footer-Item justify-content-center'>
                            <img src={require("../assets/icon/instagram.png")} />
                            <a href="https://instagram.com/zenkoplaycafe" target='_blank'>
                                Instagram
                            </a> </div>
                        <div className='d-flex Footer-Item justify-content-center'>
                            <img src={require("../assets/icon/facebook.png")} />
                            <a href='https://www.facebook.com/profile.php?id=61551432500098' target='_blank'>
                                Facebook</a>
                        </div>
                        <div className='d-flex Footer-Item justify-content-center'>
                            <img src={require("../assets/icon/twitter.png")} style={{ width: '11%', marginLeft: '-12px' }} />
                            <a href='#'>
                                Twitter</a></div>
                    </div>

                    <div className='col-4 pt-4 contact_us'>
                        <Nav.Link href="/" className={
                            activepage == '/' ? 'activeFooterMenu' : ''
                        }>Home</Nav.Link>
                        <Nav.Link href="/about"
                            className={
                                activepage == '/about' ? 'activeFooterMenu' : ''
                            }
                        > About Us </Nav.Link>
                        <Nav.Link href="/Next-Party" className={
                            activepage == '/Next-Party' ? 'activeFooterMenu' : ''
                        }> Plan your next event</Nav.Link>
                        <Nav.Link href="/gallery" className={
                            activepage == '/gallery' ? 'activeFooterMenu' : ''
                        }> Gallery </Nav.Link>
                        {/* <Nav.Link href="#offer" className={
                            activepage == '/offer' ? 'activeFooterMenu' : ''
                        }> Offer page </Nav.Link> */}






                        {/* <h5>Contact Us</h5> */}
                        {/* <Form>
                            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text" placeholder="YOUR NAME" />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                <Form.Control type="email" placeholder="YOUR EMAIL" />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
                                <Form.Control className='textArea' as="textarea" rows={3} placeholder='MESSAGE' />
                            </Form.Group>
                            <button class="btn btn-primary send" type="button">Send</button>
                        </Form> */}
                    </div>
                </div>
            </div>

            <div className='CopyRight'
                style={
                    BG_color !== undefined
                        ? { background: `${BG_color}` }
                        : { background: `transparent linear-gradient(0deg, #EEFCFF00 0%, #FAF0F3 100%) 0% 0% no-repeat padding-box` }
                }
            >
                <div className='RightDiv'>
                    &copy; <span> All Right Reserved 2023 Zenko. Made by 1</span>
                </div>

            </div>

        </>
    );
}

export default Footer;