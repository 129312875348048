import React, { useEffect } from 'react'
import Header from '../Header'
import '../css/Gallery.css'
import Footer from '../Footer'
import Mobile_Footer from '../MobileFooter'
import { Button, Card, Col } from 'react-bootstrap'
import { Home_contact_us_button_click } from '../../Links'

export const Cafe = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <div>
        <div className='Feeding-zigzag'>
          <div className='why_zenko mb-4 d-flex'>
          <a href="/" class="back-sign">&lt;Back</a>

            <div className='AboutPage-text' style={{ margin: '2% auto' }}>
              <h4 className='fw-bold mb-2'>Cafe</h4>
              <p className='menu-type-text'>Are you a parent seeking a haven of tranquility, whether with friends or for some personal respite? 
              <br></br>A place where you can indulge in delectable treats while your little ones embark on adventures and play? 
              <br></br>Look no further; welcome to Zenko Cafe
              </p>
            </div>
          </div>
          <div className='mb-4' style={{
            width: '85%',
            margin: '0 auto'
          }}>

            <div className='GalleryImgContainer mb-4 mt-2'>
              <div className='card-container Cafe'>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Cafe/image1.jpeg')} />
                  </div>
                  {/* <Card.Body>
                    <Card.Title>Birthday</Card.Title>
                  </Card.Body> */}
                </Card>
              </div>
              <div className='card-container Cafe'>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Cafe/image2.jpeg')} />
                  </div>
                  {/* <Card.Body>
                    <Card.Title>Workshops</Card.Title>
                  </Card.Body> */}
                </Card>
              </div>
              <div className='card-container Cafe'>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Cafe/image3.jpeg')} />
                  </div>
                  {/* <Card.Body>
                    <Card.Title>Events</Card.Title>
                  </Card.Body> */}
                </Card>
              </div>
            </div>


            {/* //Item menu section// */}
            <div className='d-flex justify-content-between CafeMenu mt-4'>
              <div className='CafeMenu-container'>
                <h4 className='mb-3'>Beverages</h4>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Flat White</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>80.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Cafe latte</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>80.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Cappuccino</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>100.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Latte macchiato</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>90.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Americano</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>90.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Macchiato</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>100.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Espresso</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>90.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Warm milk</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>50.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Cold coffee</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>100.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Hot chocolate</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>100.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Cold coffee with ice cream</p>
                    {/* <span>LOREM IPSUM DOLOR AMET SET</span> */}
                  </div>
                  <div className='amount'>120.00</div>
                </div>
              </div>

              <div className='CafeMenu-container'>
                <h4 className='mb-3'>Tea</h4>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Masala tea</p>
                  </div>
                  <div className='amount'>50.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Green tea</p>
                  </div>
                  <div className='amount'>60.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Watermelon ice tea</p>
                  </div>
                  <div className='amount'>110.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Peach ice tea</p>
                  </div>
                  <div className='amount'>100.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Lemon ice tea</p>
                  </div>
                  <div className='amount'>100.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Blueberry ice tea</p>
                  </div>
                  <div className='amount'>120.00</div>
                </div>
              </div>

              <div className='CafeMenu-container'>
                <h4 className='mb-3'>Chocolates</h4>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Chocolate</p>
                  </div>
                  <div className='amount'>110.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Strawberry</p>
                  </div>
                  <div className='amount'>100.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Vanilla</p>
                  </div>
                  <div className='amount'>100.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Mango</p>
                  </div>
                  <div className='amount'>100.00</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Kit kat</p>
                  </div>
                  <div className='amount'>160.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Oreo</p>
                  </div>
                  <div className='amount'>130.00</div>
                </div> <div className='d-flex justify-content-between'>
                  <div className='cafe-Item-Name'><p>Rose</p>
                  </div>
                  <div className='amount'>100.00</div>
                </div>
              </div>

            </div>

          </div>



          <a href={require('../../Menu/Cafe-Menu.pdf')} download="Zenko-Cafe-Menu" target='_blank' style={{ textDecoration: 'none', width: '100%', position: 'absolute' }}>
            <button type="submit" class="btn btn-primary mb-3 downloadMenu">
              Download Menu
            </button>
          </a>



          {/* //Image */}
          <div style={{ marginTop: '-10%' }}>
            <img src={require('../../assets/Cafe/food.png')} />
          </div>
        </div>
        <div className='Feeding-contact-zigzag'>

          <div className='About_contact d-flex pt-1'>

            <div className='contactUs pb-3'>
              <h4 className='fw-bold mb-0 mt-4'>Plan your next event</h4>
              <p>Feel free to get in touch with us anytime! We're here to assist you, whether you have to plan an event, a workshop, have questions, special requests, or simply want to chat. Reach out to us and our friendly team will be delighted to help. Your satisfaction is our priority</p>


              <Button className='contctbtn' style={{
                background: '#FFA3BE 0% 0% no-repeat padding-box'
              }} onClick={() => { window.open(Home_contact_us_button_click, '_blank') }}>Contact Us</Button>



            </div>
            <div className='About_sideImg'>
              <img src={require('../../assets/zenko_left.png')} />
            </div>
          </div>

          <div className="girls" style={{
            width: '30%',
            margin: '0 auto',
            padding: '5% 0'
          }}>
            <img src={require('../../assets/kids_logo.png')} />
          </div>
        </div>

        <div className='Desktop_footer'>
          <Footer page={'cafe'} BG_color={'transparent linear-gradient(180deg, #FFECE700 0%, #FFFDE3 100%) 0% 0% no-repeat padding-box'}></Footer>
        </div>
        <div className='Mobile_Footer d-none'>
          <Mobile_Footer page={'cafe'} BG_color={'linear-gradient(180deg, #EEFCFF00 0%, #FFFBC6 100%) 0% 0% no-repeat padding-box'}></Mobile_Footer>
        </div>

      </div>

    </>
  )
}
