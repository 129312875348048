import React, { useEffect } from 'react'
import Header from '../Header'
// import '../css/About.css'
import Footer from '../Footer'
import Mobile_Footer from '../MobileFooter'
import { Button, Col ,Card} from 'react-bootstrap'
import { Home_contact_us_button_click } from '../../Links'
import { referNow } from '../whatsapp'

export const Oasis = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <div>
        <div className='Feeding-zigzag'>
          <div className='why_zenko mb-4 d-flex'>
          <a href="/" class="back-sign">&lt;Back</a>

            <div className='AboutPage-text' style={{ margin: '2% auto' }}>
              <h4 className='fw-bold mb-2'>Imagination Oasis- Pretend play area</h4>
              <p className='menu-type-text'>Step into a world of wonder and creativity at "Imagination Oasis," the ultimate pretend play area where children's imaginations are the stars of the show. We invite you to discover the magic of make-believe and embark on unforgettable adventures of imagination. From a bustling city to a whimsical fairy land, the possibilities are endless.
              </p>
            </div>
          </div>

          <div className='mb-4' style={{
            width: '95%',
            margin: '0 auto',
            marginLeft: '5%'
          }}>

            <div className='GalleryImgContainer' style={{marginBottom:'5%'}}>
              <div className='card-container Cafe'>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Oasis/image1.JPG')} />
                  </div>
                </Card>
              </div>
              <div className='card-container Cafe'>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Oasis/image2.JPG')} />
                  </div>
                </Card>
              </div>
              <div className='card-container Cafe'>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Oasis/image3.JPG')} />
                  </div>
                </Card>
              </div>

            </div>

          </div>
        </div>

        <div className='Feeding-contact-zigzag'>

          <div className='About_contact d-flex pt-1'>

            <div className='contactUs pb-3'>
              <h4 className='fw-bold mb-0 mt-4'>Contact us</h4>
              <p>Feel free to get in touch with us anytime! We're here to assist you, whether you have to plan an event, a workshop, have questions, special requests, or simply want to chat. Reach out to us and our friendly team will be delighted to help. Your satisfaction is our priority</p>

              <Button className='d-none mobileContactBtn' style={{
                background: '#FFA3BE 0% 0% no-repeat padding-box'
              }} onClick={() => { referNow() }}>Contact Us</Button>

              <Button className='desktopContactBtn contctbtn' style={{
                background: '#FFA3BE 0% 0% no-repeat padding-box'
              }} onClick={() => { window.open(Home_contact_us_button_click, '_blank') }}>Contact Us</Button>


            </div>
            <div className='About_sideImg'>
              <img src={require('../../assets/zenko_left.png')} />
            </div>
          </div>

          <div className="girls" style={{
            width: '30%',
            margin: '0 auto',
            padding: '5% 0'
          }}>
            <img src={require('../../assets/kids_logo.png')} />
          </div>
        </div>

        <div className='Desktop_footer'>
          <Footer></Footer>
        </div>
        <div className='Mobile_Footer d-none'>
          <Mobile_Footer></Mobile_Footer>
        </div>

      </div>

    </>
  )
}
