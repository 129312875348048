import React, { useEffect } from 'react'
import Header from '../Header'
import { Button } from 'react-bootstrap';
import '../css/About.css'
import Footer from '../Footer'
import Mobile_Footer from '../MobileFooter'
import { Col } from 'react-bootstrap'

export const About_Component = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <div>
        <div className='About-zigzag'>
          {/* <div className='why_zenko mb-4 d-flex'>
            <div className='whyZenko_leftImg'>
              <img src={require('../../assets/Plane_blue.png')} />
            </div> */}


          <div className='why_zenko d-flex' style={{ 'marginTop': '2%' }}>
            <div className='About_leftImg'>
              <img src={require('../../assets/Plane_blue.png')} style={{ 'left': '-31%' }} />
            </div>

            <div className='About-text'>
              <h4 className='fw-bold mb-0'>About</h4>
              <div className='AboutPage-text'>
                <p style={{marginBottom:'10%'}}>
                Zenko is a dynamic and purposeful environment designed to facilitate holistic development in young minds and to strengthen the parent-child bond. It goes beyond mere recreation, offering a range of activities and experiences that nurture physical, cognitive, emotional, and social growth. By engaging in play, children develop motor skills, problem-solving abilities, creativity, and emotional resilience, while also learning to interact and spend quality time with friends and family.
                  </p>
                {/* <p>At ZENKO, children can play with a variety of exhibits that provide a holistic learning experience. Little ones can play with science-based exhibits (air and water flow), explore a fascinating fort, build cool stuff with huge blocks, create art masterpieces or make some groovy music!
                </p>
                <p>To ensure that our Exhibits were built to global standards, we collaborated with the Garden State Discovery Museum,
                </p> */}
              </div>      
            <div className='Avatar-img d-flex justify-content-between mt-4'>
              <div>
                <img src={require('../../assets/about/Prajakta.jpeg')} />
                <h5>Prajakta</h5>
              </div>
              <div>
                <img src={require('../../assets/about/vidhi.jpg')} />
                <h5>Vidhi</h5>
              </div>
              <div>
                <img src={require('../../assets/about/Isha.jpg')} />
                <h5>Isha</h5>
              </div>
              <div>
                <img src={require('../../assets/about/Ankita.jpeg')} />
                <h5>Ankita</h5>
              </div>
            </div>

          </div>
        </div>


        {/* </div> */}

        <div style={{ position: 'relative' }}>
          <div className='d-flex aboutText'>

            <div className='story'>
              <h5>WHAT IS ZENKO</h5>

              {/* <div className='MobileStory StoryImage d-none'>
                <div className='img_top w-100'>
                  <img src={require('../../assets/about/about_page.png')} />
                </div>
              </div> */}

              <p>Mid-2023 Pune. A mom is looking for places to take her child on a weekend. A Google search of "Things to do in Pune with children", led to many results - but no compelling ones.</p>
              <p>Parks and zoos were nice but too hot in summer. Indoor soft-play areas were fun for the child, but rather one-dimensional.</p>
            </div>
            {/* <div className='Desktop_Story StoryImage'>
              <div className='img_top w-100'>
                <img src={require('../../assets/about/about_page.png')} />
              </div>
            </div> */}
          </div>

          <div className='About_sideImg'>
            <img src={require('../../assets/zenko_left.png')} />
          </div>
        </div>
      </div>
{/* 
      <div className='About_contact d-flex' style={{ 'marginTop': '5%' }}>

        <div className='contactUs pb-3'>
          <h4 className='fw-bold mb-0 mt-4'>Contact us</h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since scrambled it to make a type specimen book.
          </p>

          <form class="row">
            <Col xs={6} md={4}>
              <label for="staticEmail2" class="visually-hidden">Name</label>
              <input type="text" class="form-control" id="staticEmail2" placeholder="Name" />
            </Col>
            <Col xs={6} md={4}>
              <label for="staticEmail2" class="visually-hidden">Email</label>
              <input type="text" class="form-control" id="staticEmail2" placeholder="Email" />
            </Col>

            <Col xs={6} md={4} className='mobileSubscribe'>
              <button type="submit" class="btn btn-primary mb-3">Submit</button>
            </Col>
          </form>

          <div className='aboutRight'>
            <img src={require('../../assets/Plane_blue_right.png')} />
          </div>

        </div>
      </div> */}


      <div className='Desktop_footer'>
        <Footer></Footer>
      </div>
      <div className='Mobile_Footer d-none'>
        <Mobile_Footer></Mobile_Footer>
      </div>

    </div >

    </>
  )
}
