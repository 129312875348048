
export const referNow = () => {

    const phoneNumber = '8282818135'; // Replace with the desired phone number
    const message = encodeURIComponent('Hi! Can I get more info on this?'); // Replace with your message

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    
    // Open WhatsApp in a new tab with the pre-typed message and phone number
    window.open(whatsappUrl, '_blank');

};
