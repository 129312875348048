import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './css/Main.css'
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Header = () => {
    const location = useLocation()
    const [activepage, setactivepage] = useState('/')

    const pathname = location.pathname
    console.log(pathname)

    useEffect(() => {
        setactivepage(pathname)
    }, [])
    return (
        <Navbar expand="lg" className="" style={{ backgroundColor: 'transparent !important' }}>
            <Container fluid className='navBar'>
                <Navbar.Brand href="/">
                    <img src={require('../assets/logo.png')} alt="Logo" width="50" height="50" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link href="/" className={
                            activepage == '/' ? 'activeMenu HomeMenu' : 'HomeMenu'
                        }>Home</Nav.Link>
                        <Nav.Link href="/about"
                            className={
                                activepage == '/about' ? 'activeMenu' : ''
                            }
                        > About Us </Nav.Link>
                        <Nav.Link href="/Next-Party" className={
                            activepage == '/Next-Party' ? 'activeMenu' : ''
                        }> Plan your next event</Nav.Link>
                        <Nav.Link href="/gallery" className={
                            activepage == '/gallery' ? 'activeMenu' : ''
                        }> Gallery </Nav.Link>
                        {/* <Nav.Link href="#offer" className={
                            activepage == '/offer' ? 'activeMenu' : ''
                        }> Offer page </Nav.Link> */}
                        <Nav.Link href="#Zenko-Footer-Mobile" className='d-none mobileSequence'> Visit Us </Nav.Link>


                    </Nav>
                    <Form className="d-flex headerPhone">
                        <a href="tel:+918282818135" style={{textDecoration:'none',color:'#000'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16" style={{ margin: '2px 5px 0 0' }}>
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            <span style={{ marginRight: '15px' }}>+91-8282818135</span>
                        </a>

                        {/* <span style={{ marginRight: '15px' }}>+91-9756384949</span> */}
                        <Nav.Link href="#Zenko-Footer" className='desktopSequence'> Visit Us </Nav.Link>

                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;


