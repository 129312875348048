import React from 'react';
import { Button } from 'react-bootstrap';
import './css/Main.css'
import './css/Main_Responsive.css'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import AboutUs from './About';
import Gallery_Section from './Gallery_section';
import Footer from './Footer';
import Header from './Header';
import Carousel_section from './Carousel_section';
import Mobile_Footer from './MobileFooter';

const Home = () => {
    return (
        <>
            <Header></Header>
            <Carousel_section></Carousel_section>
            {/* <AboutUs></AboutUs> */}
            <Gallery_Section></Gallery_Section>
            <div className='Desktop_footer'>
                <Footer></Footer>
            </div>
            <div className='Mobile_Footer d-none'>
                 <Mobile_Footer></Mobile_Footer>
            </div>

        </>
    );
}

export default Home;