import React, { useEffect, useState } from 'react';
import { Button, Nav } from 'react-bootstrap';
import './css/Main.css'
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';

const Mobile_Footer = ({ page, BG_color }) => {

    const location = useLocation()
    const [activepage, setactivepage] = useState('/')

    const pathname = location.pathname

    useEffect(() => {
        setactivepage(pathname)
    }, [])

    return (
        <>
            <div
                className={`Footer-Container ${BG_color && page === 'cafe' ? 'cafe' : page == 'gallery' ? 'GalleryBackground' : 'defaultPseudo'}`}
                id="Zenko-Footer-Mobile"
                style={
                    BG_color !== undefined
                        ? { background: `${BG_color}` }
                        : { background: `transparent linear-gradient(0deg, #EEFCFF00 0%, #FAF0F3 100%) 0% 0% no-repeat padding-box` }
                }>
                <div className='Footer'>

                    <div className='w-25'>
                        <img src={require('../assets/logo.png')} alt="Logo" width="50" height="50" />
                    </div>

                    <div className='d-flex Footer-Item justify-content-between mobile-follow'>
                        <a href="https://instagram.com/zenkoplaycafe" target='_blank'>
                            <img src={require("../assets/icon/instagram.png")} />
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=61551432500098' target='_blank'>
                            <img src={require("../assets/icon/facebook.png")} />
                        </a>

                        <img src={require("../assets/icon/twitter.png")} style={{ 'width': '11%' }} />
                        <Nav.Link href="/" className={
                            activepage == '/' ? 'activeMenu' : ''
                        }>Home</Nav.Link>
                        <Nav.Link href="/gallery" className={
                            activepage == '/gallery' ? 'activeMenu' : ''
                        }> Gallery </Nav.Link>

                        <Nav.Link href="/about"
                            className={
                                activepage == '/about' ? 'activeMenu' : ''
                            }
                        > About Us </Nav.Link>
                    </div>

                    <div className='address mobile-address'>
                        <div className='d-flex'>
                            <img src={require("../assets/icon/call.png")} />
                            <span style={{ marginLeft: '10px' }}>+91 8282818135</span>
                        </div>
                        <div className='d-flex'>
                            <img src={require("../assets/icon/email.png")} />
                            <span>Zenkoplaycafe@gmail.com</span>
                        </div>
                        <div className='d-flex'>
                            <img src={require("../assets/icon/location.png")} />
                            <span style={{ marginLeft: '14px' }}>Zenko Play Cafe, Near. Dilliwala Dairy Al-Amin Society, Opp Tender Buds, Burhanj Baug-B Colony, Salisbury Park, Bibwewadi, Pune, Maharashtra 411037</span>
                        </div>
                    </div>


                </div>


            </div >

            <div className='CopyRight'
                style={
                    BG_color !== undefined
                        ? { background: `${BG_color}` }
                        : { background: `transparent linear-gradient(0deg, #EEFCFF00 0%, #FAF0F3 100%) 0% 0% no-repeat padding-box` }
                }>
                <div style={{ textAlign: 'center', 'fontSize': '12px' }} className='mt-2 mb-2'>
                    &copy; <span> All Right Reserved 2023 Zenko. Made by 1</span>
                </div>
            </div>

        </>
    );
}

export default Mobile_Footer;