import React, { useEffect } from 'react'
import Header from '../Header'
import '../css/Birthday_party.css'
import Footer from '../Footer'
import Mobile_Footer from '../MobileFooter'
import { plan_birthday_party_page_iframe } from '../../Links'

export const BirthdayPartyPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <>
      <Header />
      <div className='form-container'>
        <div className='GoogleForm'>
          <iframe src={plan_birthday_party_page_iframe} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>          {/* <iframe src={plan_birthday_party_menu_page_iframe}/> */}

        </div>
        <div>
          <img src={require('../../assets/gift.png')} />
        </div>
      </div>

      <div className='Desktop_footer'>
        <Footer></Footer>
      </div>
      <div className='Mobile_Footer d-none'>
        <Mobile_Footer></Mobile_Footer>
      </div>

    </>
  )
}
